import React from 'react';
import "../style/contact.scss";
import {NavLink} from "react-router-dom";
import SectionContact from "../components/SectionContact";
import ContactForm from "../components/forms/ContactForm";
import DivLanguage from "../components/DivLanguage";


const Contact = () => {


    return (
        <>
            <section className="contact-top-section wave-section">
                <div className="center">
                    <DivLanguage language="fr" className="center">
                        <h1>Prendre contact avec Alphawave</h1>
                    </DivLanguage>
                    <DivLanguage language="en" className="center">
                        <h1>Contact Alphawave</h1>
                    </DivLanguage>
                </div>
            </section>
            <section className="contact-page wave-section">
                <DivLanguage language="fr" className="center">
                    <div className="wrapper-demi">
                        <div className="left">
                            <h2>
                                Nous sommes toujours prêts
                                à vous aider et à répondre
                                à vos questions
                            </h2>
                            <div className="wrapper-demi">
                                <div className="col">
                                    <span className="p20-semibold">Adresse</span>
                                    <p className="p18-regular">
                                        8 Rue Blanche
                                        75009 Paris
                                    </p>
                                </div>

                                <div className="col">
                                    <span className="p20-semibold">Mail</span>
                                    <p className="p18-regular">contact@alphawave.fr</p>
                                </div>
                                <div className="col">
                                    <span className="p20-semibold">Nous suivre</span>
                                    <NavLink to="https://www.linkedin.com/company/alphawave-france/"
                                             target={"_blank"}
                                             rel={"noopener noreferrer"}
                                             className="icon-text footer-item p18-regular">
                                        <i className="fa-brands fa-linkedin"></i>
                                        Alphawave fr
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                        <div className="right">
                            <ContactForm />
                        </div>
                    </div>

                </DivLanguage>
                <DivLanguage language="en" className="center">
                    <div className="wrapper-demi">
                        <div className="left">
                            <h2>
                                We are always ready
                                to help you and answer
                                your questions
                            </h2>
                            <div className="wrapper-demi">
                                <div className="col">
                                    <span className="p20-semibold">Address</span>
                                    <p className="p18-regular">
                                        8 Rue Blanche
                                        75009 Paris
                                    </p>
                                </div>

                                <div className="col">
                                    <span className="p20-semibold">Email</span>
                                    <p className="p18-regular">contact@alphawave.fr</p>
                                </div>
                                <div className="col">
                                    <span className="p20-semibold">Follow us</span>
                                    <NavLink to="https://www.linkedin.com/company/alphawave-france/"
                                             target={"_blank"}
                                             rel={"noopener noreferrer"}
                                             className="icon-text footer-item p18-regular">
                                        <i className="fa-brands fa-linkedin"></i>
                                        Alphawave fr
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                        <div className="right">
                            <ContactForm/>
                        </div>
                    </div>
                </DivLanguage>
            </section>

        </>
    )
};

export default Contact;