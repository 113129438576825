import React, {useEffect, useState} from 'react';
import {
    Nav, Navbar, Container, Button, Image, Dropdown, NavDropdown, Col, Row,
} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Link, NavLink} from "react-router-dom";
import "../../style/components/navigation.scss";


import Logo from "../../assets/logo/logo-alpha-white.png";
import LinkIcon from "../LinkIcon";
import DivLanguage from "../DivLanguage";

const Navigation = () => {
    const [click, setClick] = useState(false);
    const [header, setHeader] = useState(false);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const changeBackground = () => {
        if (window.scrollY >= 80) {
            setHeader(true);
        } else {
            setHeader(false);
        }
    }


    const [language, setLanguage] = useState(createInitialLanguageState("fr"));

    useEffect(() => {
        console.log("Use effect set")
        window.localStorage.setItem('language', language);
    }, [language]);
    useEffect(() => {
        console.log("Use effect get")
        const data = window.localStorage.getItem('language');
        if (data !== null) setLanguage(data);
        document.documentElement.lang = language;
    }, []);


    function createInitialLanguageState(defaultLanguage) {
        const data = window.localStorage.getItem('language');
        if (data !== null) {
            console.log("Get language from previous value")
            document.documentElement.lang = data;
            return (data)
        }

        if (data === null) {
            document.documentElement.lang = defaultLanguage;
        }

        return defaultLanguage;
    }

    const changeLanguageBack = (language) => {
        console.log("change language")
        setLanguage(language);
        window.location.reload();

    }
    const handleChange = (event) => {
        changeLanguageBack(event.target.value);
        console.log('Selected value:', event.target.value);
    };

    window.addEventListener('scroll', changeBackground);
    return (<section>

            <Navbar expand={"lg"} variant={"dark"} className={"navbar-custom p20-medium"}>
                <Navbar.Brand className="pt-3">
                    <NavLink to="/" className="logo" onClick={closeMobileMenu}>
                        <img src={Logo} style={{paddingRight: "20%"}}/>
                    </NavLink>
                </Navbar.Brand>
                <Navbar.Toggle style={{color: "white"}}/>
                <Navbar.Collapse>
                    <Nav className="navbar-nav">
                        <NavDropdown
                            title="Services"
                        >
                            <div className={"dropdown-container"}>
                                <div className={"item"}>
                                    <Dropdown.Header>
                                        <DivLanguage language={"en"}>
                                            Osiris features
                                        </DivLanguage>
                                        <DivLanguage language={"fr"}>
                                            Fonctionnalités d'Osiris
                                        </DivLanguage>
                                    </Dropdown.Header>
                                    <Dropdown.Item>
                                        <DivLanguage language={"en"}>
                                            <LinkIcon to={"/osiris/what-is-osiris"} className={"nav-link"}
                                                      value={"What is Osiris?"}
                                                      iconClass={"fa fa-question-circle"}/>
                                        </DivLanguage>
                                        <DivLanguage language={"fr"}>
                                            <LinkIcon to={"/osiris/what-is-osiris"} className={"nav-link"}
                                                      value={"Qu'est-ce qu'Osiris ?"}
                                                      iconClass={"fa fa-question-circle "}/>
                                        </DivLanguage>

                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <DivLanguage language={"en"}>
                                            <LinkIcon to={"/osiris/what-does-osiris"}
                                                      className={"nav-link"}
                                                      value={"What does Osiris do?"}
                                                      iconClass={"fa fa-rocket"}/>
                                        </DivLanguage>
                                        <DivLanguage language={"fr"}>
                                            <LinkIcon to={"/osiris/what-does-osiris"}
                                                      className={"nav-link"}
                                                      value={"Que fait Osiris?"}
                                                      iconClass={"fa fa-rocket"}/>
                                        </DivLanguage>

                                    </Dropdown.Item>

                                    <Dropdown.Item>
                                        <DivLanguage language={"en"}>
                                            <LinkIcon to={"/osiris/how-do-it"} className={"nav-link"}
                                                      value={"How does it do?"}
                                                      iconClass={"fa fa-magic"}/>
                                        </DivLanguage>
                                        <DivLanguage language={"fr"}>
                                            <LinkIcon to={"/osiris/how-do-it"} className={"nav-link"}
                                                      value={"Comment ça fonctionne ?"}
                                                      iconClass={"fa fa-magic"}/>
                                        </DivLanguage>

                                    </Dropdown.Item>
                                </div>
                                <div className={"item"}>
                                    <Dropdown.Header>
                                        <DivLanguage language={"en"}>
                                            Osiris usages
                                        </DivLanguage>
                                        <DivLanguage language={"fr"}>
                                            Usages d'Osiris
                                        </DivLanguage>
                                    </Dropdown.Header>
                                    <Dropdown.Item>
                                        <DivLanguage language={"en"}>
                                            <LinkIcon to={"/osiris/why"} className={"nav-link"}
                                                      value={"Why to use Osiris?"}
                                                      iconClass={"fa fa-bolt"}/>
                                        </DivLanguage>
                                        <DivLanguage language={"fr"}>
                                            <LinkIcon to={"/osiris/why"} className={"nav-link"}
                                                      value={"Pourquoi utiliser Osiris ?"}
                                                      iconClass={"fa fa-bolt"}/>
                                        </DivLanguage>


                                    </Dropdown.Item>
                                </div>

                                <div className={"item"}>
                                    <Dropdown.Header>
                                        <DivLanguage language={"en"}>
                                            Infrastructure
                                        </DivLanguage>
                                        <DivLanguage language={"fr"}>
                                            Infrastructure
                                        </DivLanguage>
                                    </Dropdown.Header>
                                    <Dropdown.Item>
                                        <DivLanguage language={"en"}>
                                            <LinkIcon to={"/osiris/cloud-deployment"} className={"nav-link"}
                                                      value="SaaS"
                                                      iconClass={"fa fa-cloud"}/>
                                        </DivLanguage>
                                        <DivLanguage language={"fr"}>
                                            <LinkIcon to={"/osiris/cloud-deployment"} className={"nav-link"}
                                                      value="SaaS"
                                                      iconClass={"fa fa-cloud"}/>
                                        </DivLanguage>

                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <DivLanguage language={"en"}>
                                            <LinkIcon to={"/osiris/onprem-deployment"} className={"nav-link"}
                                                      value="On premise"
                                                      iconClass={"fa fa-building-o"}/>
                                        </DivLanguage>
                                        <DivLanguage language={"fr"}>
                                            <LinkIcon to={"/osiris/onprem-deployment"} className={"nav-link"}
                                                      value="On premise"
                                                      iconClass={"fa fa-building-o"}/>
                                        </DivLanguage>
                                    </Dropdown.Item>
                                </div>
                                <div className={"item"}>
                                    <Dropdown.Header>
                                        <DivLanguage language={"en"}>
                                            Resources
                                        </DivLanguage>
                                        <DivLanguage language={"fr"}>
                                            Ressources
                                        </DivLanguage>
                                    </Dropdown.Header>
                                    <Dropdown.Item>
                                        <DivLanguage language={"en"}>
                                            <LinkIcon to={"/osiris/how-to-guide"} className={"nav-link"}
                                                      value={"Practical guides"}
                                                      iconClass={"fa fa-book"}/>
                                        </DivLanguage>
                                        <DivLanguage language={"fr"}>
                                            <LinkIcon to={"/osiris/how-to-guide"} className={"nav-link"}
                                                      value={"Guides pratiques"}
                                                      iconClass={"fa fa-book"}/>
                                        </DivLanguage>
                                    </Dropdown.Item>

                                </div>
                            </div>

                        </NavDropdown>

                        <div className="navitem">
                            <DivLanguage language={"en"}>
                                <NavLink to="/who-are-we"
                                         className='p20-medium'
                                         onClick={closeMobileMenu}>
                                    Our team
                                </NavLink>
                            </DivLanguage>
                            <DivLanguage language={"fr"}>
                                <NavLink to="/who-are-we"
                                         className='p20-medium'
                                         onClick={closeMobileMenu}>
                                    Notre équipe
                                </NavLink>
                            </DivLanguage>

                        </div>

                        <div className="navitem">
                            <DivLanguage language={"en"}>
                                <NavLink to="/contact"
                                         className='p20-medium'
                                         onClick={closeMobileMenu}>
                                    Contact
                                </NavLink>
                            </DivLanguage>
                            <DivLanguage language={"fr"}>
                                <NavLink to="/contact"
                                         className='p20-medium'
                                         onClick={closeMobileMenu}>
                                    Contact
                                </NavLink>
                            </DivLanguage>

                        </div>
                        <div className="navitem">
                            <select className="select-mobile"
                                    value={language}
                                    onChange={handleChange}>
                                <option>fr</option>
                                <option>en</option>
                            </select>

                        </div>
                    </Nav>

                    <select className="select-desktop" value={language}
                            onChange={handleChange}>
                        <option>fr</option>
                        <option>en</option>
                    </select>
                </Navbar.Collapse>

            </Navbar>

        </section>


    );
}
export default Navigation;