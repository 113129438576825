import {createBrowserRouter, Outlet, RouterProvider} from "react-router-dom";
import HomePage from "./pages/home/HomePage";
import PageError from "./pages/PageError";
import Contact from "./pages/Contact";
import OsirisPage from "./pages/Osiris/OsirisPage";
import ProductsPage from "./pages/Products/ProductsPage";
import WhoAreWE from "./pages/WhoAreWe/WhoAreWePage";
import Header from "./components/menu/Header";
import LegalInformations from "./pages/LegalInformations";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import CookieConsent from "./components/CookieConsent";
import {useCookies} from "react-cookie";
import WhatIsOsiris from "./pages/Osiris/Features/WhatIsOsiris";
import HowOsirisDoIt from "./pages/Osiris/Features/HowOsirisDoIT";
import WhyUseOsiris from "./pages/Osiris/Usages/WhyUseOsiris";
import HowToGuides from "./pages/Resources/HowToGuides";
import VismeAskComponent from "./components/visme/AskSupport";
import VismeNewsLetterComponent from "./components/visme/NewsletterSubscribe";
import WhatDoesOsiris from "./pages/Osiris/Features/WhatDoesOsiris";
import CloudDeploymentPage from "./pages/Osiris/Infrastructure/CloudDeploymentPage";
import OnPremiseDeployPage from "./pages/Osiris/Infrastructure/OnPremiseDeployment";


const router = createBrowserRouter([
    {
        path: '/',
        element: <Root/>,
        errorElement: <PageError/>,
        children: [
            {
                path: '',
                element: <HomePage/>
            },
            {
                path: 'who-are-we',
                element: <WhoAreWE/>
            },
            {
                path: 'contact',
                element: <Contact/>
            },
            {
                path: 'legal-informations',
                element: <LegalInformations/>
            },
            {
                path: 'privacy-policy',
                element: <PrivacyPolicy/>
            },
            {
                path: 'osiris/what-is-osiris',
                element: <WhatIsOsiris/>
            },
            {
                path: 'osiris/what-does-osiris',
                element: <WhatDoesOsiris/>
            },
            {
                path: 'osiris/how-do-it',
                element: <HowOsirisDoIt/>
            }
            ,
            {
                path: 'osiris/why',
                element: <WhyUseOsiris/>
            },
            {
                path: 'osiris/how-to-guide',
                element: <HowToGuides/>
            },
            {
                path:'osiris/cloud-deployment',
                element: <CloudDeploymentPage/>
            },
            {
                path:'osiris/onprem-deployment',
                element: <OnPremiseDeployPage/>
            }

        ]
    }
]);

function Root() {

    const [cookies] = useCookies(["cookieConsent"]);

    return <>

        <Header/>

        <main>
            <VismeNewsLetterComponent/>
            <ScrollToTop/>

            <Outlet/>

        </main>
        {/*{!cookies.cookieConsent && <CookieConsent/>}*/}

        <Footer/>
    </>
}


const App = () => {


    return <RouterProvider router={router}/>
}

export default App