import React from 'react';
import CardWithBorder from "../components/card/CardWithBorder";
import "../style/privacy-policy.scss"
import DivLanguage from "../components/DivLanguage";

const PrivacyPolicy = (props) => {

    return (
        <>
            <section className="privacy-policy-top-section wave-section">
                <DivLanguage language="fr" className="center">
                    <div className="title">
                        <h1>Politique de confidentialité</h1>
                    </div>
                </DivLanguage>
                <DivLanguage language="en" className="center">
                    <div className="title">
                        <h1>Privacy Policy</h1>
                    </div>
                </DivLanguage>

            </section>

            <section className="privacy-policy-sec2 wave-section">
                <DivLanguage language="fr" className="center">
                    <h2>Qui sommes-nous ?</h2>
                    <p className="p18-medium">
                        L’adresse de notre site Web est : <br/>
                        <span className="span-blue">https://alphawave.fr</span>
                    </p>
                    <p className="p18-medium">
                        ALPHAWAVE – 8 Rue Blanche,
                        75009, Paris – FRANCE
                    </p>
                    <p className="p18-medium">
                        contact@alphawave.fr
                    </p>
                </DivLanguage>
                <DivLanguage language="en" className="center">
                    <h2>Who are we?</h2>
                    <p className="p18-medium">
                        Our website address is: <br/>
                        <span className="span-blue">https://alphawave.fr</span>
                    </p>
                    <p className="p18-medium">
                        ALPHAWAVE – 8 Rue Blanche,
                        75009, Paris – FRANCE
                    </p>
                    <p className="p18-medium">
                        contact@alphawave.fr
                    </p>
                </DivLanguage>

            </section>

            <section className="privacy-policy-sec3">
                <DivLanguage language="fr" className="center">
                    <h2>Utilisation des données personnelles collectées</h2>

                    <div className="wrapper-tiers">
                        <CardWithBorder title="Formulaires de contact"
                                        icon="fa-solid fa-envelope">
                            <p className="p18-regular">
                                Votre navigation sur notre site est
                                complètement anonyme, les données
                                personnelles saisies ne seront jamais
                                partagées avec des tiers.
                            </p>
                            <p className="p18-regular">
                                Nous collectons uniquement votre adresse
                                email, votre nom ainsi que le message que
                                vous nous envoyez et le sujet du message.
                                Ces informations serons conservées pour
                                une durée maximum trois ans.
                            </p>
                        </CardWithBorder>

                        <CardWithBorder title="Documents téléchargeables"
                                        icon="fa-solid fa-file-arrow-down">
                            <p className="p18-regular">
                                Lors de votre navigation, vous pourrez
                                choisir de télécharger des documents
                                que nous mettons à disposition sur notre
                                société et ses produits. Nous demandons
                                une adresse email pour accéder au lien
                                de téléchargement.
                            </p>
                            <p className="p18-regular">
                                Nous collectons uniquement votre adresse
                                email et l’adresse IP utilisée. Ces
                                informations ne seront conservées que pour
                                une durée maximum de trois ans.
                            </p>
                        </CardWithBorder>

                        <CardWithBorder title="Cookies"
                                        icon="fa-solid fa-cookie-bite">
                            <p className="p18-regular">
                                Nous ne collectons pas de cookies.
                            </p>
                        </CardWithBorder>
                    </div>
                </DivLanguage>

                <DivLanguage language="en" className="center">
                    <h2>Use of Collected Personal Data</h2>

                    <div className="wrapper-tiers">
                        <CardWithBorder title="Contact Forms"
                                        icon="fa-solid fa-envelope">
                            <p className="p18-regular">
                                Your browsing on our site is
                                completely anonymous, and personal data entered will never
                                be shared with third parties.
                            </p>
                            <p className="p18-regular">
                                We only collect your email address,
                                your name, as well as the message you
                                send us and the subject of the message.
                                This information will be kept for
                                a maximum duration of three years.
                            </p>
                        </CardWithBorder>

                        <CardWithBorder title="Downloadable Documents"
                                        icon="fa-solid fa-file-arrow-down">
                            <p className="p18-regular">
                                While browsing, you may choose
                                to download documents we provide about our
                                company and its products. We request
                                an email address to access the download link.
                            </p>
                            <p className="p18-regular">
                                We only collect your email
                                address and the IP address used. This
                                information will be kept for
                                a maximum duration of three years.
                            </p>
                        </CardWithBorder>

                        <CardWithBorder title="Cookies"
                                        icon="fa-solid fa-cookie-bite">
                            <p className="p18-regular">
                                We don't use any cookies on the website.
                            </p>
                        </CardWithBorder>
                    </div>
                </DivLanguage>

            </section>


            <section className="privacy-policy-sec4 wave-section">
                <DivLanguage language="fr" className="bg-white">
                    <div className="center">
                        <h2>Les droits que vous avez sur vos données</h2>

                        <p className="p18-medium">
                            Si vous ne voulez pas recevoir d’emails
                            de notre part pour quelque raison que ce
                            soit, envoyez-nous un email à
                            <span className="span-blue"> dataprivacy@alphawave.fr</span>
                        </p>

                        <p className="p18-medium">
                            Si vous laissez des informations sur le
                            site, vous pouvez demander à recevoir un
                            fichier contenant toutes les données
                            personnelles que nous possédons à votre
                            sujet. Vous pouvez également demander la
                            suppression des données personnelles vous
                            concernant à l’adresse
                            <span className="span-blue"> dataprivacy@alphawave.fr</span>
                            . Cela ne prend pas en compte les données
                            stockées à des fins administratives,
                            légales ou pour des raisons de sécurité.
                        </p>

                        <p className="p18-medium">
                            Par ailleurs, nous ne réalisons aucune
                            opération marketing ou de profilage à
                            l’aide de vos données personnelles.
                        </p>
                    </div>
                </DivLanguage>
                <DivLanguage language="en" className="bg-white">
                    <div className="center">
                        <h2>The Rights You Have Over Your Data</h2>

                        <p className="p18-medium">
                            If you do not want to receive emails
                            from us for any reason, please send us an email at
                            <span className="span-blue"> dataprivacy@alphawave.fr </span>
                        </p>

                        <p className="p18-medium">
                            If you leave information on the
                            site, you can request to receive a
                            file containing all the personal data
                            we hold about you. You can also request the
                            deletion of personal data concerning you at
                            <span className="span-blue"> dataprivacy@alphawave.fr</span>.
                            This does not take into account data
                            stored for administrative, legal, or security purposes.
                        </p>

                        <p className="p18-medium">
                            Furthermore, we do not perform any
                            marketing or profiling operations using your personal data.
                        </p>
                    </div>
                </DivLanguage>

            </section>

        </>
    )
};

export default PrivacyPolicy;